import { useRoutes } from 'react-router-dom';

// routes
import {MainRoutes, Main, Login, Detail,Store,Categories,CategoryDetail,Subscribe,Contactus,Policypage,ErrorPage} from './MainRoutes';
// import AuthenticationRoutes from './AuthenticationRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([MainRoutes, Main, Login, Detail,Store,Categories,CategoryDetail,Subscribe,Contactus,Policypage,ErrorPage]);
}
