import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
// import Layout from './Layout';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsCategory = Loadable(lazy(() => import('views/utilities/Category')));
const UtilsStore = Loadable(lazy(() => import('views/utilities/Store')));
const UtilsCoupon = Loadable(lazy(() => import('views/utilities/Coupon')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const UtilSpecial = Loadable(lazy(() => import('views/utilities/Specialoffer')));
const UtilAds = Loadable(lazy(() => import('views/utilities/Ads')));

// sample page routing
const PolicyPage = Loadable(lazy(() => import('views/sample-page/Policypage')));
const SEOpage = Loadable(lazy(() => import('views/sample-page/SEO')));
const Settingpage = Loadable(lazy(() => import('views/sample-page/Setting')));
const Pluginpage = Loadable(lazy(() => import('views/sample-page/Plugins')));
const Subscriberspage = Loadable(lazy(() => import('views/sample-page/Subscribers')));
const Loginpage = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const HomePage = Loadable(lazy(() => import('views/Home/Home')));
const StoreDetailPage = Loadable(lazy(() => import('views/Home/StoreDetail')));
const Storeview = Loadable(lazy(() => import('views/Home/Store/Storeview')));
const CategoiesPage = Loadable(lazy(()=> import('views/Home/Categoriespage/Categoriespage')));
const Categorydetail = Loadable(lazy(()=>import('views/Home/Categoriespage/CategoryDetail')));
const Subscribepage = Loadable(lazy(()=> import('views/Home/Subscribe/Subscribe')));
const ContactForm = Loadable(lazy(()=> import('views/Home/Contact/Contactus')));
const PolicyPageview = Loadable(lazy(()=> import('views/Home/Policy/Policy')));
const Errorpage = Loadable(lazy(()=> import('views/Home/Errorpage/Error')));

// const Navbarpage = Loadable(lazy(()=> import('views/Home/Navbar')));
// const Footerpage = Loadable(lazy(()=> import('views/Home/Footer')));
// import { Routes, Route } from 'react-router-dom';
// ==============================|| MAIN ROUTING ||============================== //
// const storedOffer = localStorage.getItem('offer');
// const selectedStoredata = storedOffer ? JSON.parse(storedOffer) : null;
// const DynamicPolicyPage = () => {
//   const [policyData, setPolicyData] = useState({});

//   useEffect(() => {
//     // Fetch data from your API
//     axios.get('https://couponwink.com/api/policypages')
//       .then((response) => {
//         console.log("policlyid",response.data)
//         // Assuming your API response has a title and description field
//         setPolicyData(response.data);
//       })
//       .catch((error) => {
//         console.error('Error fetching policy data:', error);
//       });
//   }, []); 
//   return <PolicyPageview title={policyData.title} description={policyData.description} />;
// };
const MainRoutes = {
  path: '/Mainlayout',
  element: <MainLayout />,
  children: [
    {
      path: '/Mainlayout',
      element: <DashboardDefault />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-typography',
          element: <UtilsCategory />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-color',
          element: <UtilsStore />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-shadow',
          element: <UtilsCoupon />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-special',
          element: <UtilSpecial />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-Ads',
          element: <UtilAds />
        }
      ]
    },
   
    // {
    //   path: 'icons',
    //   children: [
    //     {
    //       path: 'material-icons',
    //       element: <UtilsMaterialIcons />
    //     }
    //   ]
    // },
    {
      path: 'sample-page',
      children: [
        {
          path: 'Policy',
          element: <PolicyPage />
        },
        {
          path: 'SEO',
          element: <SEOpage />
        },
        {
          path: 'Setting',
          element: <Settingpage />
        },
        {
          path: 'plugins',
          element: <Pluginpage />
        },
        {
          path: 'subscribers',
          element: <Subscriberspage />
        }
      ]
    }
  ]
};

const Main = {
  path: '/',
  element: (
    // <Layout>
      <HomePage />
    // </Layout>
  )
};
const Detail = {
  path: `/coupon/:category/:storeTitle`,
  element: (
      <StoreDetailPage />
  )
};
// const Detail = {
//   path: '/coupon/:category?/:storeTitle',
//   element: (<StoreDetailPage />)
// };


const Login = {
  path: '/login',
  element: <Loginpage />
};

const Store = {
  path: '/Store',
  element: (
    // <Layout>
      <Storeview />
    // </Layout>
  )
};

const Categories = {
  path:'/Categories',
  element:(
    // <Layout>
      <CategoiesPage/>
    // </Layout>
  )
}
const CategoryDetail = {
  path:'/store_list',
  element:(
    // <Layout>
      <Categorydetail/>
    // </Layout>
  )
}
const Subscribe = {
  path:'/subscribe',
  element:(
<Subscribepage/>
  )
  
}
const Contactus = {
  path:'/contactus',
  element:(<ContactForm/>
  ) 
}
const Policypage = {
  path:'/Policy/:policyTitle',
  element:(<PolicyPageview/>
  ) 
}
const ErrorPage = {
  path:'/Error',
  element:(<Errorpage/>
  ) 
}

export { MainRoutes, Main, Login, Detail, Store,Categories,CategoryDetail,Subscribe,Contactus,Policypage,ErrorPage};
