import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { StoreDataProvider } from 'Context/StoredataContext';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';

// ==============================|| APP ||============================== //

const App = () => {
  
  const customization = useSelector((state) => state.customization);
  const [SEOData, setSeoData] = useState();
  const [gethead, setgethead] = useState();
  const getSeodata = () => {
    axios
      .get('https://couponwink.com/api/seo')
      .then((res) => {
        setSeoData(res.data);
      })
      .catch((err) => console.log(err));
  };
  const GetPluginHead = () => {
    axios
      .get('https://couponwink.com/api/plugins/Head')
      .then((res) => {
        setgethead(res.data.firstPluginhead);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getSeodata();
    GetPluginHead();
    
  }, []);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <StoreDataProvider>
            {SEOData &&
              SEOData?.map((e, index) => (
                <Helmet key={index}>
                  <meta charSet="utf-8" />
                  <meta name="viewport" content="width=device-width, initial-scale=1" />
                  {/* Additional meta tags can be added here */}
                  <title>{e.socialTitle}</title>
                  <meta name="keywords" content={e.metaKeywords} />
                  <meta name="description" content={e.socialDescription} />
                  <meta name="metadescription" content={e.metaDescription} />
                  <meta property="og:image" content={e.socialImage} />
                  <meta property="og:image:width" content="600" />
                  <meta property="og:image:height" content="400" />
                  {gethead && (
                    <script type="text/javascript">
                    {String(gethead?.head)}
                  </script>
                  )}
                </Helmet>
              ))}
            <Routes />
          </StoreDataProvider>
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
